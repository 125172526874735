import memoize from 'lodash/memoize';
import { IMonitoringContext } from './MonitoringContext';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { localStoragePropsMap } from '../LocalStorage/localStoragePropsMap';

export interface IInteractionItemData {
  started: Date;
  entityId?: string;
}

export interface IInteractionsData {
  [index: string]: IInteractionItemData;
}

export const monitoringPropsMap = memoize(
  (flowAPI: ControllerFlowAPI): IMonitoringContext => {
    return {
      startInteraction: async (interactionName: string, entityId?: string) => {
        const { setItem, requestItem } = await localStoragePropsMap(flowAPI);
        const interactionsString = requestItem('interactions');
        const interactions: IInteractionsData = interactionsString
          ? JSON.parse(interactionsString)
          : {};

        interactions[interactionName] = {
          started: new Date(),
          entityId,
        };

        setItem('interactions', interactions);

        flowAPI.fedops.interactionStarted(interactionName);
      },
      endInteraction: async (interactionName: string, entityId?: string) => {
        const { requestItem } = await localStoragePropsMap(flowAPI);
        const interactionsString = requestItem('interactions');
        const interactions: IInteractionsData = interactionsString
          ? JSON.parse(interactionsString)
          : {};
        const currentInteraction = interactions[interactionName];

        if (currentInteraction) {
          const diff = differenceInMinutes(
            new Date(),
            new Date(currentInteraction.started),
          );

          if (
            diff < 2 &&
            (!currentInteraction.entityId ||
              entityId === currentInteraction.entityId)
          ) {
            flowAPI.fedops.interactionEnded(interactionName);
          }
        } else {
          console.warn(`The interaction ${interactionName} doesnt ended.`);
        }
      },
    };
  },
);
