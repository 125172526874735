import { ILocation } from '@wix/native-components-infra/dist/src/types/types';
import { ILocationProviderProps } from './LocationProvider';
import { ILocationContext, ScreenNamesByPageId } from './LocationContext';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import memoize from 'lodash/memoize';
import {
  appendQueryStringToUrl,
  generateQueryString,
  getTargetUrl,
} from './urlHelpers';
import { biChangePage } from '../../services/biHelpers';

export interface ILocationProps extends ILocationContext {}

export const locationProviderPropsMap = memoize(
  (flowAPI: ControllerFlowAPI): ILocationProviderProps => {
    const location = (flowAPI.controllerConfig.wixCodeApi.location || {
      path: [],
      query: {},
      url: '',
    }) as ILocation;

    const query = location.query;
    const { isPreview } = flowAPI.environment;
    return {
      // @ts-expect-error
      location: {
        path: location.path,
        query: location.query,
        url: location.url,
        baseUrl: location.baseUrl,
      },
      baseUrl: location.baseUrl,
      path: [...location.path],
      query: {
        ...query,
      },
      goToPage: async (options) => {
        if (isPreview) {
          const targetUrl = await getTargetUrl(options, flowAPI);
          await flowAPI.controllerConfig.wixCodeApi.location.to(targetUrl);
        } else {
          await biChangePage(flowAPI.bi, ScreenNamesByPageId[options.pageId]);
          const url = appendQueryStringToUrl({
            url: await getTargetUrl(options, flowAPI),
            query: generateQueryString(options.queryParams),
          });
          flowAPI.controllerConfig.wixCodeApi.location.to(url);
        }
      },
      goToExternalUrl(url: string) {
        flowAPI.controllerConfig.wixCodeApi.location.to(url);
      },
    };
  },
);

export function getUrlParams(flowAPI: ControllerFlowAPI): {
  slug?: string;
  pageName: string;
  navigationType?: 'step' | 'section' | 'description';
  navigationId?: string;
} {
  if (flowAPI.environment.isViewer) {
    const location = locationProviderPropsMap(flowAPI);
    const [pageName, slug, navigationType, navigationId] = location.path;
    return {
      slug,
      pageName,
      navigationType: navigationType as any,
      navigationId,
    };
  }
  return {
    pageName: '',
    navigationId: '',
    navigationType: undefined,
    slug: '',
  };
}

export function getChallengeSlugFromLocation(flowAPI: ControllerFlowAPI) {
  const location = locationProviderPropsMap(flowAPI);
  let path = location.path;
  if (
    location.path.length <= 1 ||
    ['ChallengesList'].includes(flowAPI.componentName)
  ) {
    return '';
  }
  if (location.path.includes('challenges-web-ooi')) {
    // path in iframe editor and viewer is ["prod", "editor", "challenges-web-ooi", "1.1880.0", "ChallengesPage", <challenge id which is optional>]
    path = path.slice(5, 10);
  }
  if (flowAPI.environment.isViewer) {
    // if (path[0].search('challenge') < 0) {
    //   return '';
    // }

    return path[1];
  }
  return path[location.path.length - 1];
}
